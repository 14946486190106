<template>
    <div class="main">
        <div class="search">
            <el-select v-model="log.failType" @change="getLogFailList" size="mini" placeholder="로그타입"
                       style="width:110px;margin-left: 5px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="아이디오류" :value="managerConst.LogLoginFail.FAIL_TYPE_USERNAME">아이디오류</el-option>
                <el-option label="비번오류" :value="managerConst.LogLoginFail.FAIL_TYPE_PASSWORD">비번오류</el-option>
                <el-option label="블럭회원" :value="managerConst.LogLoginFail.FAIL_TYPE_BLOCK">블럭회원</el-option>
                <el-option label="블럭회원" :value="managerConst.LogLoginFail.FAIL_TYPE_IPBLOCK">블럭회원</el-option>
            </el-select>
            <el-input type="text" size="mini" v-model="log.nickname" placeholder="닉네임"
                      style="width: 150px;margin-left: 5px"></el-input>
            <el-button type="primary" @click="getLogFailList" size="mini" style="margin-left: 10px">검색</el-button>

        </div>

        <div class="data">
            <el-table
                    :data="logList"
                    style="width: 100%"
                    max-height="730"
                    border>
                <el-table-column
                        label="날짜"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="IP"
                        width="350">
                    <template slot-scope="scope">
                        {{scope.row.ip}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="아이디"
                        width="150">
                    <template slot-scope="scope">
                        {{scope.row.username}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="닉네임"
                        width="150">
                    <template slot-scope="scope">
                        <span class="badge badge-danger" style="cursor: pointer" v-if="scope.row.userId"
                              @click="editUser(scope.row.userId,managerConst.MODE_EDIT)">
                                {{scope.row.nickname}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="입력비번"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.passwd}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="실패사유"
                        width="130">
                    <template slot-scope="scope">
                        <span v-if="scope.row.failType ==  managerConst.LogLoginFail.FAIL_TYPE_USERNAME">아이디 오류</span>
                        <span style="color: #ffa500"
                              v-if="scope.row.failType ==  managerConst.LogLoginFail.FAIL_TYPE_PASSWORD">비밀번호 오류</span>
                        <span style="color: #ff0000"
                              v-if="scope.row.failType ==  managerConst.LogLoginFail.FAIL_TYPE_BLOCK">블랙회원</span>
                        <span style="color: #ff0000"
                              v-if="scope.row.failType ==  managerConst.LogLoginFail.FAIL_TYPE_IPBLOCK">IP블럭</span>


                    </template>
                </el-table-column>


            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {updateGroup} from "../../network/manager/groupRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {getLogCashPointList} from "../../network/manager/logCashPointRequest";
    import {getModifiedHistoryList, updateAllModifiedHistory} from "../../network/manager/logModifyRequest";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {getLoginLogList} from "../../network/manager/logLoginRequest";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import {Loading} from "element-ui";
    import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";
    import {getUserLoginFailLog} from "../../network/manager/userRequest";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$partnerEditToast = PartnerEditToast
    Vue.prototype.$agentEditToast = AgentEditToast
    export default {
        name: "ManagerLoginFailHistory",
        mixins: [manager],
        components: {DateSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
                log: {},
                logList: [],

            }
        },
        methods: {
            editPartner(id, mode) {
                this.$agentEditToast({'agentId': id, 'mode': mode})
            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            getLogFailList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                this.log.startDate = this.startDate;
                this.log.endDate = this.endDate;
                getUserLoginFailLog(this.log, this.pageNum, this.pageSize).then(res => {
                    this.logList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getLogFailList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getLogFailList();
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },
        },
        created() {
            this.getLogFailList()
        },
    }
</script>

<style scoped>

</style>